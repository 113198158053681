



















































import { Component, Vue } from 'vue-property-decorator'
import { apiGetUserInfo, apiGetMusician } from '@/api/profile'
import { faced, certInfo } from '@/api/users'
import Bind from '@/components/Dialog/bind.vue'
import NickName from '@/components/Dialog/nickName.vue'


@Component({
  name: 'Form',
  components: {
    Bind,
    NickName
  }
})
export default class extends Vue {
  private data = {
    avatar_url: '',
    nick_name: '',
    phone: '',
    name: '',
    introduction: '',
    createdat: '',
    id_code: '',
    real_name: ''
  }
  private dialogVisible1 = false;
  private dialogVisible2 = false;
  private otherWayToClose = false;

  private rules = {
    name: [
      { required: true, message: '请输入艺名', trigger: 'blur'},
      { min: 1, max: 8, message: '长度在 1 到 8 个字符内', trigger: 'blur' }
    ],
    desc: [
      { required: false, message: '请输入简介', trigger: 'blur' },
    ],
    number: [
      { required: true, message: '请输入手机号', trigger: 'blur'},
      { min: 11, max: 11, message: '手机号格式有误', trigger: 'blur' }
    ],
    code: [
      { required: true, message: '请输入验证码', trigger: 'blur'},
      { min: 1, max: 6, message: '验证格式有误', trigger: 'blur' }
    ]
  }

  created(): void {
    this.getData();
    this.checkUserInfo();
    let t: any = this.$route.query.response
    if(t) {
      let cretId = JSON.parse(t).extInfo.certifyId
      this.getCertRes(cretId)
    }
  }
  private onModify() {
    this.$router.push({
      path: '/profile/modify'
    })
  }

  private getData():void {
    apiGetUserInfo().then((res: any)=> {
      this.data.avatar_url = res.data.avatar_url;
      this.data.nick_name = res.data.nick_name;
      this.data.phone = res.data.phone;
      this.data.createdat = res.data.CreatedAt
    })
    apiGetMusician().then((res: any)=> {
      this.data.name = res.data.name
      this.data.introduction = res.data.introduction
    })
    certInfo().then((res: any)=> {
      if(res.data.face_verify === 'T') {
        this.data.real_name = res.data.real_name
        this.data.id_code = res.data.id_code
      }
    })
  }
  
  private checkUserInfo():void {
    apiGetMusician().then((res: any)=> {
      if(!res.data.name) {
        this.$message({
          message: '请设置艺名后继续',
          type: 'warning'
        })
        this.dialogVisible2 = true;
      }
    })
  }

  private onCretHandle() {
    this.$router.push({
      path: '/profile/realname'
    })
  }

  private closeDiaHandle() {
    this.dialogVisible1 = false;
    this.dialogVisible2 = false;
  }

  private onPhoneModify() {
    this.dialogVisible1 = true;
    this.otherWayToClose = true;
  }

  private onNicknameModify() {
    this.dialogVisible2 = true;
    this.otherWayToClose = true;
  }

  private getCertRes(certifyId: string) {
   faced({certifyId}).then((res: any)=>{
      this.$message({
        message: '认证通过',
        type: 'success'
      })
      this.getData();
   })
  }
}
