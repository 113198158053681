













































import { Component, Vue, Watch } from 'vue-property-decorator'
import { submit } from '@/api/chain'
import { certInfo } from '@/api/users'


@Component({
  name: 'Form'
})
export default class extends Vue {
  private authorList = [
    {
      label: '词作者',
      value: ''
    },
    {
      label: '曲作者',
      value: ''
    },
    {
      label: '表演者',
      value: ''
    },
    {
      label: '录音制作者',
      value: ''
    }
  ]
  private options = [
    {
      label: '词作者',
      value: '词作者'
    },
    {
      label: '曲作者',
      value: '曲作者'
    },
    {
      label: '表演者',
      value: '表演者'
    }
  ]
  private info = {
    real_name: '',
    id_code: ''
  }

  created():void {
    this.getCertInfo()
  }
  private onSubmit() {
    if(this.authorList[0].value && this.authorList[1].value && this.authorList[2].value && this.authorList[3].value) {
      this.$confirm('请确保所有存证信息准确无误，存证后无法修改。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            submit({
              wid: Number(this.$route.query.wid),
              lyric_author: this.authorList[0].value,
              music_author: this.authorList[1].value,
              actors: this.authorList[2].value,
              recorders: this.authorList[3].value
            }).then((res:any) => {
              console.log(res)
              instance.confirmButtonLoading = false;
              done();
              this.$message({
                message: '操作成功',
                type: 'success'
              })
              this.$router.go(-1)
            })
          } else {
            done();
          }
        }
      })
    }else{
      this.$message({
        message: '请补全信息后提交',
        type: 'warning'
      })
    }
  }

  private getCertInfo() {
    certInfo().then((res: any)=> {
      if(res.data.face_verify === 'T') {
        this.info = res.data
      }else{
        this.$message({
          message: '请实名认证后继续',
          type: 'warning'
        })
        this.$router.push({
          path: '/profile/realname'
        })
      }
    })
  }

  
}
