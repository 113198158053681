




































///<reference path="./utils.d.ts"/>
let isUpload = false;
let uploadType = 0;  // 通过哪种类型上传调用的裁剪器，0是封面图，1是歌曲图片
import { Component, Vue } from 'vue-property-decorator'
import { face } from '@/api/users'
const axios = require('axios');
import { randomWord, isPC } from '@/utils'

@Component({
  name: 'Form',
})
export default class extends Vue {
  private form = {
    name: '',
    idnumber: ''
  }
  private checked = false
  private dialogVisible = false

  private created() {
    // if(!isPC()) {
    //   this.$message({
    //     message: '请在电脑端进行实名认证',
    //     type: 'warning'
    //   })
    //   this.$router.go(-1)
    // }
    // console.log(this.certInitHandle())
  }
  private certInitHandle():any {
    return getMetaInfo()
  }
  private rules = {
    name: [
      { required: true, message: '请输入真实姓名', trigger: 'blur' },
    ],
    idnumber: [
      { required: true, message: '请输入身份证号码', trigger: 'blur' },
      {
        pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
        message: '证件号码格式有误',
        trigger: 'blur'
      }
    ]
  }
  
  private onSubmit():void {
    if(!this.checked) {
      this.$message({
        message: '请知晓实名认证授权声明后继续',
        type: 'warning'
      })
    }else{
      // console.log(getMetaInfo)
      // console.dir(getMetaInfo())
      face({
        name: this.form.name,
        no: this.form.idnumber,
        meta_info: JSON.stringify(getMetaInfo())
      }).then((res: any)=> {
        window.open(res.data.CertifyUrl)
        console.log(res)
      })
      // let t:any = this.CertInitHandle()
      // console.info(t)
      // console.log(this.form)
      // let no = Math.random().toString(36).slice(-8)+Math.random().toString(36).slice(-8)+Math.random().toString(36).slice(-8)+Math.random().toString(36).slice(-8)
      // console.log(no)
      // axios.post('https://cloudauth.aliyuncs.com/InitFaceVerify', {
      //   SceneId: 1000001556,
      //   OuterOrderNo: randomWord(true, 32, 32),
      //   ProductCode: 'ID_PRO',
      //   CertType: 'IDENTITY_CARD',
      //   CertName: this.form.name,
      //   CertNo: this.form.idnumber,
      //   ReturnUrl: `${document.location.protocol}//${window.location.host}/profile/index`,
      //   MetaInfo: JSON.stringify(this.certInitHandle())
      // }).then((res: any)=>{
      //   console.log(res)
      // })
    }
  }

  private authBookClickHandle() {
    this.dialogVisible = true;
  }
}
