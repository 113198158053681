



































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { apiModifyMusician, apiGetUserInfo, apiGetMusician, apiGetCode, apiBindPhone } from '@/api/profile'

@Component({
  name: 'Form'
})
export default class extends Vue {
  private form1 = {
    avatar_url: '',
    nick_name: '',
    phone: '',
    code: ''
  }
  private form2 = {
    name: '',
    introduction: '',
  }
  private nickNameDisabled: boolean = true
  private isCodeDisabled: boolean = false
  private time = 60
  private timerID: number = -1

  private rules1 = {
    phone: [
      { required: true, message: '请输入手机号', trigger: 'blur'},
      { min: 11, max: 11, message: '手机号格式有误', trigger: 'blur' }
    ],
    code: [
      { required: true, message: '请输入验证码', trigger: 'blur'},
      { min: 1, max: 6, message: '验证格式有误', trigger: 'blur' }
    ]
  }
  private rules2 = {
    name: [
      { required: true, message: '请输入艺名', trigger: 'blur'},
      { min: 1, max: 15, message: '长度在 1 到 20 个字', trigger: 'blur' }
    ],
    introduction: [
      { required: false, max: 300, message: '最多设置300个字符', trigger: 'blur' },
    ]
  }

  created():void {
    this.getData();
  }
  private onSubmitPhone() {
    (this.$refs.form1 as Form).validate((valid: boolean) => {
      if (valid) {
        apiBindPhone({
          phone: this.form1.phone,
          code: this.form1.code
        }).then((res: any)=> {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.time = 60;
          clearInterval(this.timerID)
          this.$router.go(-1);
        })
      } else {
        this.$message({
          message: '请按照要求填写信息',
          type: 'warning'
        })
      }
    })
  }

  private onSubmitMusician() {
    (this.$refs.form2 as Form).validate((valid: boolean) => {
      if (valid) {
        (apiModifyMusician as any)({
          name: this.form2.name,
          introduction: this.form2.introduction
        }).then((res: any)=> {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.$router.go(-1);
        })
      } else {
        this.$message({
          message: '请按照要求填写信息',
          type: 'warning'
        })
      }
    })
  }

  private getCode() {
    apiGetCode({
      phone: this.form1.phone
    }).then((res: any)=> {
      this.timerID = window.setInterval(()=> {
        this.time--;
      }, 1000)
    })
  }

  private onCancel() {
    this.$message({
      message: 'cancel!',
      type: 'warning'
    })
  }

  private getData():void {
    apiGetUserInfo().then((res: any)=> {
      this.form1.avatar_url = res.data.avatar_url;
      this.form1.nick_name = res.data.nick_name;
      this.form1.phone = res.data.phone;
    })
    apiGetMusician().then((res: any)=> {
      this.form2.name = res.data.name
      this.form2.introduction = res.data.introduction
    })
  }

  @Watch('time')
  private onTimeChange(value: number) {
    console.log(value)
    if(value === 0){
      clearInterval(this.timerID);
      this.time = 60;
    }
  }
}
