import request from '@/utils/request'

export const apiGetUserInfo = () =>
  request({
    url: '/v2/userinfo',
    method: 'get'
  })

export const apiGetMusician = () =>
  request({
    url: '/v2/musician',
    method: 'get'
  })

export const apiModifyMusician = (data: any) =>
  request({
    url: '/v2/musician/update',
    method: 'post',
    data
  })

export const apiGetCode = (data: any) =>
  request({
    url: `/v2/smscode/${data.phone}`,
    method: 'post'
  })
  
export const apiBindPhone = (params: any) =>
  request({
    url: `/v2/bindphone`,
    method: 'post',
    params
  })



  
