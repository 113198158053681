








































import { Component, Prop, Vue } from 'vue-property-decorator'
import { apiModifyMusician, apiGetUserInfo, apiGetMusician, apiGetCode, apiBindPhone } from '@/api/profile'
import { Form } from 'element-ui'
@Component({
  name: 'Hamburger'
})
export default class extends Vue {
  @Prop({ default: false }) private dialogVisible!: boolean
  @Prop({ default: false }) private otherWayToClose!: boolean
  private form = {
    avatar_url: '',
    nick_name: '',
    phone: '',
    code: ''
  }
  private isCodeDisabled: boolean = false
  private time = 60
  private timerID: number = -1
  private rules = {
    phone: [
      { required: true, message: '请输入手机号', trigger: 'blur'},
      { min: 11, max: 11, message: '手机号格式有误', trigger: 'blur' }
    ],
    code: [
      { required: true, message: '请输入验证码', trigger: 'blur'},
      { min: 6, max: 6, message: '验证码长度为6位', trigger: 'blur' }
    ]
  }
  private toggleClick() {
    this.$emit('toggle-click')
  }
  private closeHandle() {
    this.$emit('closeDiaHandle');
  }

  private getCode() {
    if(this.form.phone.length === 11) {
      apiGetCode({
        phone: this.form.phone
      }).then((res: any)=> {
        this.isCodeDisabled = true;
        this.timerID = window.setInterval(()=> {
          this.time--;
        }, 1000)
      })
    }else{
      this.$message({
        message: '手机号格式有误',
        type: 'warning'
      })
    }
  }

  private onSubmitPhone() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if (valid) {
        apiBindPhone({
          phone: this.form.phone,
          code: this.form.code
        }).then((res: any)=> {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.time = 60;
          clearInterval(this.timerID)
          // this.$router.go(-1);
          this.closeHandle();
          (this.$parent as any).getData();
        })
      } else {
        this.$message({
          message: '请按照要求填写信息',
          type: 'warning'
        })
      }
    })
  }
}
