











































import { Component, Prop, Vue } from 'vue-property-decorator'
import { apiModifyMusician, apiGetUserInfo, apiGetMusician, apiGetCode, apiBindPhone } from '@/api/profile'
import { Form } from 'element-ui'
@Component({
  name: 'Hamburger'
})
export default class extends Vue {
  @Prop({ default: false }) private dialogVisible!: boolean
  @Prop({ default: false }) private otherWayToClose!: boolean
  private form = {
    name: '',
    introduction: '',
  }
  private isCodeDisabled: boolean = false
  private time = 60
  private timerID: number = -1
  private rules = {
    name: [
      { required: true, message: '请输入艺名', trigger: 'blur'},
      { min: 1, max: 15, message: '长度在 1 到 20 个字', trigger: 'blur' }
    ],
    introduction: [
      { required: false, max: 300, message: '最多设置300个字符', trigger: 'blur' },
    ]
  }
  created() {
    apiGetMusician().then((res: any)=> {
      console.log(res)
      this.form.name = res.data.name;
      this.form.introduction = res.data.introduction;
    })
  }
  private toggleClick() {
    this.$emit('toggle-click')
  }
  private closeHandle() {
    this.$emit('closeDiaHandle');
  }

  private getCode() {
    // if(this.form.phone.length === 11) {
    //   apiGetCode({
    //     phone: this.form.phone
    //   }).then((res: any)=> {
    //     this.timerID = window.setInterval(()=> {
    //       this.time--;
    //     }, 1000)
    //   })
    // }else{
    //   this.$message({
    //     message: '手机号格式有误',
    //     type: 'warning'
    //   })
    // }
  }

  private onSubmitMusician() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if (valid) {
        (apiModifyMusician as any)({
          name: this.form.name,
          introduction: this.form.introduction
        }).then((res: any)=> {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.closeHandle();
          (this.$parent as any).getData();
          // this.$router.go(-1);
        })
      } else {
        this.$message({
          message: '请按照要求填写信息',
          type: 'warning'
        })
      }
    })
  }
}
